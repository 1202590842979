import Glide from '@glidejs/glide';

const mediaCarousels: NodeListOf<HTMLElement> = document.querySelectorAll('.media-carousel__inner');

mediaCarousels.forEach((item) => {
    const slides: NodeListOf<HTMLDivElement> = item.querySelectorAll('.media-carousel__item');

    let componentType = 'carousel' as string;

    if (slides.length < 2) componentType = 'slider';

    var glide = new Glide(item, {
        type: componentType,
        perView: 1,
        peek: {
            before: 474,
            after: 474
        },
        gap: 60,
        rewind: true,
        bound: true,
        breakpoints: {
            1380: {
                perView: 1,
                gap: 60,
                peek: {
                    before: 400,
                    after: 400
                },
            },
            1180: {
                perView: 1,
                gap: 60,
                peek: {
                    before: 300,
                    after: 300
                },
            },
            900: {
                perView: 1,
                gap: 30,
                peek: {
                    before: 200,
                    after: 200
                },
            },
            700: {
                perView: 1,
                gap: 30,
                peek: {
                    before: 100,
                    after: 100
                },
            },
            550: {
                perView: 1,
                gap: 30,
                peek: {
                    before: 80,
                    after: 80
                },
            },
            480: {
                perView: 1,
                gap: 30,
                peek: {
                    before: 0,
                    after: 0
                },
            },
        },
    });

    glide.mount();

    if (componentType === 'slider') {
        const leftArrow = item.querySelector('.media-carousel__arrow-left') as HTMLElement;
        const rightArrow = item.querySelector('.media-carousel__arrow-right') as HTMLElement;

        leftArrow.classList.add('hidden');
        rightArrow.classList.add('hidden');

        glide.disable();
    }
});

mediaCarousels.forEach(carousel => {
    const playButtons: NodeListOf<HTMLElement> = carousel.querySelectorAll('.media-carousel__button');
    const videos: NodeListOf<HTMLVideoElement> = carousel.querySelectorAll('.modal-video__video');
    const modals: NodeListOf<HTMLElement> = carousel.querySelectorAll('.modal-video');

    playButtons.forEach((button, index) => {
        button.addEventListener('click', () => {
            const video = videos[index] as HTMLVideoElement;
            const modal = modals[index] as HTMLDialogElement;

            modal?.showModal();
            video?.play();
        });
    });

    window.addEventListener('click', event => {
        videos.forEach(video => {
            if (video && (event.target as Element).className == 'modal-video') {
                closeVideoModal(video);
            }

            if (video && (event.target as Element).id == 'modal-video__close') {
                closeVideoModal(video);
            }

            if ((event.target as Element).id == 'modal-video__close-icon') {
                closeVideoModal(video);
            }
        });
    });

    function closeVideoModal(video: HTMLVideoElement) {
        video.pause();
        video.currentTime = 0;

        modals.forEach(modal => {
            const modalElement = modal as HTMLDialogElement;

            modalElement.close();
        });
    }
});
