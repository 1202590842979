import Glide from '@glidejs/glide';
import { handleCarouselButtons } from '../../../helpers/carouselRange';

const logoCarousels: NodeListOf<HTMLDivElement> = document.querySelectorAll('.logo-carousel__inner');

logoCarousels.forEach((item) => {
    var glide = new Glide(item, {
        type: 'slider',
        perView: 6,
        gap: 40,
        bound: true,
        rewind: true,
        breakpoints: {
            1300: {
                perView: 5,
            },
            1100: {
                perView: 4,
            },
            450: {
                perView: 1.2,
            },
        },
    });

    glide.mount();

    // Solution to prevent right edge slide from being cut off
    const handleCarouselWidth = () => {
        const glideSlides = item.querySelector('.glide__slides') as HTMLElement;
        const width = glideSlides.clientWidth;
        const newWidth = width - 40;

        glideSlides.style.width = `${newWidth}px`;
    }

    handleCarouselWidth()

    const slides: NodeListOf<HTMLDivElement> = item.querySelectorAll('.logo-carousel__item');

    handleCarouselButtons(slides, glide.settings.perView, item, glide)

    glide.on('resize', () => {
        handleCarouselWidth()
        handleCarouselButtons(slides, glide.settings.perView, item, glide)

        glide.go(`=0`);
    })
});

