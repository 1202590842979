const filters: NodeListOf<HTMLElement> = document.querySelectorAll(".filters");
let selectedFilters = [] as Array<any>;

const closeAllDropdowns = () => {
  const contents: NodeListOf<HTMLElement> = document.querySelectorAll(".filters__dropdown-content");
  contents.forEach(item => item.classList.remove("filters__dropdown-content--active"));
};

document.addEventListener('click', closeAllDropdowns);

document.addEventListener('keydown', (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    closeAllDropdowns();
  }
});

filters.forEach((filter, i) => {
  const tags = filter.querySelector(".filters__tags") as HTMLElement;
  const dropdown = filter.querySelector('.filters__dropdown-content') as HTMLElement;
  const dropdownButton = filter.querySelector('.filters__dropdown-button') as HTMLElement;
  const bottom = filter.querySelector('.filters__bottom') as HTMLElement;

  const createTag = (tag: string) => {
    const value = tag;

    const button = document.createElement("button");
    button.className = "flex flex--align-center filters__tag";
    button.innerHTML = `
        <span class="filters__tag-name">${value}</span>
        <i class="zmdi zmdi-close filters__tag-icon"></i>
      `;

    createTagListener(button, value);
    tags.appendChild(button);
  };

  const removeTag = (value: string) => {
    const buttons: NodeListOf<HTMLElement> = document.querySelectorAll('.filters__tag');

    buttons.forEach(button => {
      const buttonValue = button.querySelector('.filters__tag-name').textContent;

      if (buttonValue === value) {
        button.parentElement.removeChild(button);
      }
    });
  };

  const createTagListener = (button: HTMLElement, value: string) => {
    button.addEventListener("click", () => {
      removeTag(value);

      const checkbox = Array.from(document.querySelectorAll("input[type='checkbox']")).find(cb => (cb as HTMLInputElement).value === value);
      if (checkbox) {
        (checkbox as HTMLInputElement).checked = false;
      }

      const label = Array.from(document.querySelectorAll('.filters__dropdown-content label')).find(lbl => lbl.querySelector("input").value === value);
      if (label) {
        label.classList.remove("filters__dropdown-label--active");
      }

      const index = selectedFilters.findIndex(filter => filter.name === value);
      if (index > -1) {
        selectedFilters.splice(index, 1);
      }

      toggleBottom()
    });
  };

  const toggleBottom = () => {
    if (tags.children.length > 0) {
      bottom.classList.add("filters__bottom--active");
    } else {
      bottom.classList.remove("filters__bottom--active");
    }
  }

  dropdownButton.addEventListener('click', (e) => {
    e.stopPropagation();

    const contents: NodeListOf<HTMLElement> = document.querySelectorAll(".filters__dropdown-content");

    contents.forEach((item, num) => {
      if (num !== i) {
        item.classList.remove("filters__dropdown-content--active");
      }
    })

    if (dropdown.classList.contains("filters__dropdown-content--active")) {
      dropdown.classList.remove("filters__dropdown-content--active");
    } else {
      dropdown.classList.add("filters__dropdown-content--active");
    }
  });

  const labels: NodeListOf<HTMLElement> = filter.querySelectorAll('.filters__dropdown-content label');

  labels.forEach(label => {
    const handleEvent = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();

      const checkbox: HTMLInputElement = label.querySelector("input");
      checkbox.checked = !checkbox.checked;

      if (checkbox.checked) {
        if (!selectedFilters.some(filter => filter.name === checkbox.value)) {
          selectedFilters.push({ name: checkbox.value, category: i });
          createTag(checkbox.value);
        }
      } else {
        const index = selectedFilters.findIndex(filter => filter.name === checkbox.value);

        if (index > -1) {
          selectedFilters.splice(index, 1);
          removeTag(checkbox.value);
        }
      }

      label.classList.toggle("filters__dropdown-label--active", checkbox.checked);

      toggleBottom()
    };

    label.addEventListener('click', handleEvent);

    label.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleEvent(e);
      }
    });
  });
});

export default selectedFilters;