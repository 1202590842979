import Glide from '@glidejs/glide';
import { setRangeMax, handleCarouselButtons, handleContainerPaddingLeft } from '../../../helpers/carouselRange';

const imageCardCarousels: NodeListOf<HTMLDivElement> = document.querySelectorAll('.image-carousel__inner');

imageCardCarousels.forEach((item) => {
    var glide = new Glide(item, {
        type: 'slider',
        perView: 3.5,
        gap: 20,
        rewind: false,
        bound: true,
        breakpoints: {
            1200: {
                perView: 2.5,
                gap: 30,
            },
            760: {
                perView: 1.7,
                gap: 30,
            },
            550: {
                perView: 1.2,
                gap: 20,
            },
        },
    });

    glide.mount();

    const imageCardCarouselContainer = item.parentElement as HTMLElement;
    const carouselRange = item.querySelector('.image-carousel__range') as HTMLInputElement;

    carouselRange.addEventListener('input', (e) => {
        setTimeout(() => {
            glide.go(`=${carouselRange.value}`);
        }, 500);
    });

    const slides: NodeListOf<HTMLDivElement> = item.querySelectorAll('.image-carousel__item');
    setRangeMax(slides, carouselRange, glide.settings.perView);
    handleCarouselButtons(slides, glide.settings.perView, item, glide)
    handleContainerPaddingLeft(slides, glide.settings.perView,imageCardCarouselContainer)

    glide.on('move.after', () => {
        const currentIndex = glide.index;
        carouselRange.value = currentIndex;
    });

    glide.on('resize', () => {
        setRangeMax(slides, carouselRange, glide.settings.perView);
        handleCarouselButtons(slides, glide.settings.perView, item, glide);
        handleContainerPaddingLeft(slides, glide.settings.perView,imageCardCarouselContainer);
        glide.go(`=0`);
    });
});
