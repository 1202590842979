import Glide from '@glidejs/glide';
import { setRangeMax, handleCarouselButtons, handlePadding } from '../../../helpers/carouselRange';

const careerCarousels: NodeListOf<HTMLDivElement> = document.querySelectorAll('.careers-carousel__inner');

if (careerCarousels.length > 0) {
    careerCarousels.forEach((item) => {
        var glide = new Glide(item, {
            type: 'slider',
            perView: 3,
            gap: 60,
            bound: true,
            rewind: false,
            breakpoints: {
                1200: {
                    perView: 3,
                    gap: 30,
                },
                800: {
                    perView: 1.7,
                    gap: 30,
                },
                600: {
                    perView: 1.4,
                    gap: 30,
                },
                480: {
                    perView: 1.2,
                    gap: 30,
                },
            },
        });

        glide.mount();

        const carouselRange = item.querySelector('.careers-carousel__range') as HTMLInputElement;

        carouselRange.addEventListener('input', (e) => {
            setTimeout(() => {
                glide.go(`=${carouselRange.value}`);
            }, 500);
        });

        const carouselContainer = item.parentElement as HTMLElement;
        const slides: NodeListOf<HTMLDivElement> = item.querySelectorAll('.careers-carousel__item');
        setRangeMax(slides, carouselRange, glide.settings.perView);
        handleCarouselButtons(slides, glide.settings.perView, item, glide)

        glide.on('move.after', () => {
            const currentIndex = glide.index;
            carouselRange.value = currentIndex;
        });

        glide.on('resize', () => {
            setRangeMax(slides, carouselRange, glide.settings.perView);
            handleCarouselButtons(slides, glide.settings.perView, item, glide);
            handlePadding(slides, glide.settings.perView, carouselContainer)
            glide.go(`=0`);
        });

        // Covers the phantom gap that appears in the carousel when there are less than 3 slides
        if (slides.length < 3) {
            const carouselItems: NodeListOf<HTMLElement> = item.querySelectorAll('.careers-carousel__item');

            carouselItems.forEach((item) => {
                item.style.flex = "1";
            })
        }
    });
}
