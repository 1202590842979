import selectedFilters from "../filterElement/filterElement";
import { updatePagination, disablePagination, enablePagination } from "../pagination/pagination";
const cardGrid = document.querySelector(".card-grid") as HTMLElement;

if (cardGrid) {
  const cardGridFilters = cardGrid.querySelector(".card-grid__filters") as HTMLElement;
  const cardGridTrigger = cardGrid.querySelector(".card-grid__trigger") as HTMLElement;
  const paginationElement = cardGrid.querySelector(".pagination") as HTMLElement;
  const grid = cardGrid.querySelector(".card-grid__grid") as HTMLElement;
  const labels: NodeListOf<HTMLElement> = cardGrid.querySelectorAll('.filters__dropdown-content label');

  const parentId = cardGrid.getAttribute("data-parent-id") as string;

  let types: Array<any> = [];
  let topics: Array<any> = [];
  let pageSize: number = 8;

  let pageNumber: number = 1;
  let hasNextPage: boolean = false;
  let hasPreviousPage: boolean = false;
  let totalCount: number = 0;
  let totalPages: number = 0;

  let cards: Array<any> = [];

  const baseURL = window.location.origin;

  const fetchData = async () => {
    disablePagination(paginationElement)
    disableFilters()
    getFilters()

    const url = `${baseURL}/umbraco/api/imagecards/GetChildPages?parentId=${parentId}&page=${pageNumber}&pageSize=${pageSize}${generateFilterString("featureTags", topics)}${generateFilterString("mediaTypes", types)}`;

    try {
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }

      const data = await response.json();
      cards = data.items;

      setPaginationData(data)
      renderCards()
      enablePagination(paginationElement)
      enableFilters()
    } catch (error) {
      grid.innerHTML = "<span class='flex flex--align-center flex--justify-center card-grid__info'>There was an error</span>"
      console.error('There was a problem with the fetch operation:', error);
    }
  }

  const setPaginationData = (data: any) => {
    totalPages = data.totalPages
    totalCount = data.totalItems
    hasNextPage = data.hasNextPage
    hasPreviousPage = data.hasPreviousPage

    updatePagination(pageNumber, totalPages, totalCount, pageSize, hasNextPage, hasPreviousPage)
  }

  const renderCards = () => {
    grid.innerHTML = "";

    if (cards.length > 0) {
      cards.forEach(card => {
        const cardElement = document.createElement("a");
        cardElement.href = card.link.url;
        cardElement.className = "card-grid__card flex flex--column";

        cardElement.innerHTML = `
          ${card.imageUrl ? `
            <div class='card-grid__card-image-container'>
              <img src='${baseURL}${card.imageUrl}' alt='${card.title}' class='card-grid__card-image' />

              ${card.featureTag ? `
                <div class='card-grid__card-badge'>
                  ${card.featureTag}
                </div>`
              : ""}

            </div>`
          : ""}
          <div class='card-grid__card-content flex flex--column flex--align-start'>
          ${card.featureTag && !card.imageUrl ? `
            <div class='card-grid__card-badge' style='position: relative; top: -5px; left: -20px;'>
              ${card.featureTag}
            </div>`
          : ""}
            <div class="card-grid__card-row flex flex--wrap flex--justify-between flex--align-center">

            ${card.date ? `
              <span class='card-grid__card-date'>
                ${card.date}
              </span>`
            : ""}

                <span class="card-grid__card-type">${card.mediaType}</span>
            </div>
            <h4 class='card-grid__card-title'>
                ${card.title}
            </h4>
            <p class='card-grid__card-info'>
                ${card.content}
            </p>
            <button class="button button--primary-simple  flex flex--justify-center flex--align-center" disabled>
              <span class="button__label">${card.link.name}</span>
              <i class="zmdi zmdi-long-arrow-right"></i>
            </button>
            </div>
        `

        grid.appendChild(cardElement)
      })
    } else {
      grid.innerHTML = "<span class='flex flex--align-center flex--justify-center card-grid__info'>No results found</span>"
    }
  }

  const getFilters = () => {
    const filters = selectedFilters;

    types = [];
    topics = [];

    filters.forEach(filter => {
      if (filter.category === 0) {
        types.push(filter.name)
      } else if (filter.category === 1) {
        topics.push(filter.name)
      }
    })
  }

  const generateFilterString = (category: string, array: Array<string>) => {
    let string = "";

    if (array.length > 0) {
      string += "&";
    }

    array.forEach((item, i) => {
      string += `${category}=${item}`;

      if (i < array.length - 1) {
        string += "&";
      }
    })

    return string;
  }

  const createTagListener = () => {
    const tags: NodeListOf<HTMLButtonElement> = cardGrid.querySelectorAll(".filters__tag");

    tags.forEach(tag => {
      tag.addEventListener("click", () => {
        pageNumber = 1
        fetchData()
      })

      tag.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          pageNumber = 1
          fetchData()
        }
      });
    })
  }

  const disableFilters = () => {
    const tags: NodeListOf<HTMLButtonElement> = cardGrid.querySelectorAll(".filters__tag");

    labels.forEach(label => {
      label.classList.add("filters__dropdown-label--disabled");
      label.setAttribute("tabindex", "-1");
    })

    tags.forEach((tag: HTMLButtonElement) => {
      tag.disabled = true;
    })
  }

  const enableFilters = () => {
    const tags: NodeListOf<HTMLButtonElement> = cardGrid.querySelectorAll(".filters__tag");

    labels.forEach(label => {
      label.classList.remove("filters__dropdown-label--disabled");
      label.setAttribute("tabindex", "0");
    })

    tags.forEach((tag: HTMLButtonElement) => {
      tag.disabled = false;
    })
  }

  labels.forEach(label => {
    label.addEventListener("click", () => {
      pageNumber = 1
      fetchData()
      createTagListener()
    })

    label.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        pageNumber = 1
        fetchData()
        createTagListener()
      }
    });
  })

  if (cardGridTrigger) {
    cardGridTrigger.addEventListener("click", () => {
      cardGridFilters.classList.toggle("card-grid__filters--active");
    })
  }

  paginationElement.addEventListener("click", e => {
    const target = e.target as HTMLElement;

    if (target.tagName === 'BUTTON') {
      const li = target.closest('button');

      if (li.classList.contains('prev')) {
        pageNumber = pageNumber - 1;
      } else if (li.classList.contains('next')) {
        pageNumber = pageNumber + 1;
      } else if (li.classList.contains('pagination__numb')) {
        pageNumber = parseInt(target.textContent);
      }

      disablePagination(paginationElement)
      fetchData();
      updatePagination(pageNumber, totalPages, totalCount, pageSize, hasNextPage, hasPreviousPage)
    }
  })

  fetchData()
}